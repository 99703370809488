export const AUTH_KEY: string = "msal.idtoken";
export const AVATAR_KEY: string = "ocp.photo";
export const PERMISSIONS_KEY: string = "ocp.permissions";
export const TS_AND_CS_KEY: string = "ocp.accepted-terms-and-conditions";
export const APP_SELECTED_PERMISSIONS_KEY: string =
  "ocp.app-selected-permissions";
export const ACCOUNT_INFO_KEY: string = "msal.account-info";

export const AUFLS_APP_PATH: string = "/aufls";
export const ACS_APP_PATH: string = "/acs";
export const POCP_APP_PATH: string = "/pocp";
export const NZGB_APP_PATH: string = "/nzgb";
export const DANDE_APP_PATH: string = "/soregister";
export const OM_APP_PATH: string = '/om'

export const TS_AND_CS_VERSION: string = "May 2021";

export const FILE_UPLOAD_LIMIT_MB = 1000;
export const FILE_UPLOAD_LIMIT_BYTES = FILE_UPLOAD_LIMIT_MB * 1024 * 1024;

export const DATE_FORMAT = "dd MMM yyyy, HH:mm:ss";
export const SHORT_DATE_FORMAT = "dd/MM/yyyy";

export const DATE_TIME_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss';

export const MUI_DATE_TIME_PICKER_FORMAT = "dd MM yyyy, HH:mm:ss";

// Excel specific date format - not used by date-fns
export const EXCEL_DATE_FORMAT = "dd MMM yyyy, hh:mm:ss";
export const EXCEL_FILE_DATE_FORMAT = "yyyyMMdd_hhmmss";
