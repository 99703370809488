import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ActionCheck from "@material-ui/icons/CheckCircle";
import { useDialog } from "muibox";
import { useCallback, useRef } from "react";
import Markdown from "../reusable-components/layout/Markdown";
import { fetchTextFile } from "../shared/fetchFile";

const useStyles = makeStyles((theme) => ({
  buttonSpacing: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: "1px",
  },
}));

export async function fetchTermsAndConditionText() {
  return await fetchTextFile("/assets/portal/termsAndConditions.md");
}

async function fetchOMAppTermsAndConditionText() {
  return await fetchTextFile("/assets/portal/omTermsAndConditions.md");
}

export async function alertTsAndCs(dialog, termsAndConditions: string) {
  try {
    await dialog.alert({
      message: <Markdown children={termsAndConditions} />,
      ok: { text: "Ok", startIcon: <ActionCheck /> },
      /*
       * AC states we don't need a 'not accept' button, but muibox won't return
       * a value unless it is a confirm dialog, which shows a cancel button. So
       * we use a confirm dialog, but one which has the cancel button 'hidden'
       */
      cancel: { text: "", startIcon: <span /> },
    });
  } catch {
    // canceled
  }
}

export async function confirmTsAndCs(dialog, isOMApp = false) {
  try {
    const termsAndConditions = isOMApp
      ? await fetchOMAppTermsAndConditionText()
      : await fetchTermsAndConditionText();
    await dialog.confirm({
      message: <Markdown children={termsAndConditions} />,
      ok: { text: "Accept", startIcon: <ActionCheck /> },
      /*
       * AC states we don't need a 'not accept' button, but muibox won't return
       * a value unless it is a confirm dialog, which shows a cancel button. So
       * we use a confirm dialog, but one which has the cancel button 'hidden'
       */
      cancel: { text: "", startIcon: <span /> },
    });

    return true;
  } catch {
    // cancelled
  }

  return false;
}

export function TermsAndConditions({ isOMApp = false }) {
  const dialog = useDialog();
  const classes = useStyles();
  const termsAndConditionsRef = useRef("");

  const displayTsAndCs = useCallback(async () => {
    if (!termsAndConditionsRef.current) {
      if (isOMApp)
        termsAndConditionsRef.current = await fetchOMAppTermsAndConditionText();
      else termsAndConditionsRef.current = await fetchTermsAndConditionText();
    }

    const termsAndConditions = termsAndConditionsRef.current;

    alertTsAndCs(dialog, termsAndConditions);
  }, [dialog, isOMApp]);

  return (
    <Link
      className={classes.buttonSpacing}
      component="button"
      variant="body2"
      onClick={() => {
        displayTsAndCs();
      }}
    >
      Terms and Conditions
    </Link>
  );
}
