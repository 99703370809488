import { Chip, makeStyles, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useCallback } from "react";
import { useNotify, useTranslate } from "react-admin";
import Markdown from "../reusable-components/layout/Markdown";
import { COLOUR_TRANSPOWER_BLUE } from "../reusable-components/tp-materialui";
import useOCPAuthProvider from "../shared/useOCPAuthProvider";
import useUserContext from "../shared/useUserContext";
import { IMenuLink } from "../types";

const useStyles = makeStyles((theme) => ({
  featuredCard: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  cardContent: {
    flexDirection: "column",
    display: "flex",
    flex: "1",
    minWidth: "33%",
    minHeight: "180px",
    padding: "15px",
    position: "relative",
  },
  cardContentBody: {
    display: "flex",
    flex: "1 0 auto",
  },
  cardContentControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  cardContentControlChip: {
    borderRadius: 4,
    height: 35,
    paddingLeft: 15,
    paddingRight: 15,
    cursor: "pointer",
  },
  cardMedia: {
    height: 150,
    width: "100%",
    borderRadius: 2,
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  cardActionArea: {
    display: "flex",
    flexDirection: "column",
  },
  cardContentBold: {
    fontWeight: 600,
    fontStyle: "italic",
  },
  cardContentTitle: {
    fontFamily: "'Calibri', 'sans-serif'",
    color: COLOUR_TRANSPOWER_BLUE,
  },
}));

interface FeaturedAppCardContentProps extends FeaturedAppProps {
  showLogin: boolean;
  authorised: boolean;
}

const FeaturedAppCardContent = (props: FeaturedAppCardContentProps) => {
  const {
    authorised,
    appLink,
    content,
    imageText,
    imageUrl,
    showLogin,
    title,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Card className={classes.featuredCard}>
      <CardActionArea className={classes.cardActionArea}>
        {imageUrl ? (
          <CardMedia
            className={classes.cardMedia}
            image={imageUrl}
            title={imageText}
          />
        ) : (
          <></>
        )}
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.cardContentTitle}
          >
            <Markdown children={title} />
          </Typography>
          <div className={classes.cardContentBody}>
            <Markdown children={content} />
          </div>
          <div className={classes.cardContentControls}>
            {showLogin && (
              <Chip
                className={classes.cardContentControlChip}
                label={translate("portal.action.login")}
                title={translate("portal.action.login")}
                color="primary"
              />
            )}
            {authorised && (
              <Chip
                className={classes.cardContentControlChip}
                label={translate("portal.action.gotoApp", {
                  appName: appLink?.name,
                })}
                title={translate("portal.action.gotoApp", {
                  appName: appLink?.name,
                })}
                color="primary"
              />
            )}
            {!authorised && !showLogin && (
              <Typography
                paragraph={true}
                variant="body2"
                className={classes.cardContentBold}
                gutterBottom={true}
              >
                {translate("portal.error.noAppAccess")}
              </Typography>
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const checkPermissions = (userContext, link) => {
  if (!link.permission) {
    return true;
  } else if (link.permission.test(userContext.orgPermission?.permission)) {
    return true;
  } else {
    return userContext.appPermissions.some((perm) =>
      link.permission.test(perm)
    );
  }
};

export type AppShortName = "AUFLS" | "ACS" | "POCP" | "NZGB" | "DANDE" | "OM";

export interface FeaturedAppProps {
  shortName: AppShortName;
  content: string;
  imageUrl?: string;
  imageText: string;
  title: string;
  appLink: IMenuLink;
  hasGuestAccess?: boolean;
}

export default function FeaturedApp(props: FeaturedAppProps) {
  const notify = useNotify();
  const authProvider = useOCPAuthProvider();

  const userContext = useUserContext();
  const isGuest = userContext.isGuest;

  const authorised = checkPermissions(userContext, props.appLink);

  const handleLoginClick = useCallback(async () => {
    authProvider.login("").catch(() => {
      notify("portal.error.loginFailed");
    });
  }, [authProvider, notify]);

  return isGuest && !props.hasGuestAccess ? (
    <Grid item xs={12} style={{ display: "inherit", margin: "5px" }}>
      <CardActionArea component="a" onClick={() => handleLoginClick()}>
        <FeaturedAppCardContent
          {...props}
          showLogin={isGuest}
          authorised={authorised}
        />
      </CardActionArea>
    </Grid>
  ) : (
    <Grid item xs={12} style={{ display: "inherit", margin: "5px" }}>
      <CardActionArea
        disabled={!authorised}
        component="a"
        href={props.appLink?.href}
      >
        <FeaturedAppCardContent
          {...props}
          showLogin={false}
          authorised={authorised}
        />
      </CardActionArea>
    </Grid>
  );
}
