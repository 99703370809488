import { useContext } from "react";
import { DashboardComponent } from "react-admin";
import { ConfigContext } from "../..";
import Dashboard from "../../portal/Dashboard";
import { getPortalLinks, portalLinkOM } from "../../portal/links";
import OMDashboard from "../../portal/OMDashboard";
import { IMenuLink } from "../../types";

function useOMApp() {
  const config = useContext(ConfigContext);

  const shouldLoadGridCustomerPortal = () => {
    if (!config.REACT_APP_OM_APP_SUBDOMAINS) return false;
    const subdomains = config.REACT_APP_OM_APP_SUBDOMAINS?.split(",");
    const currentSubdomain = window.location.host.split(".")[0];

    return subdomains.includes(currentSubdomain);
  };

  return config.REACT_APP_OM_APP === "TRUE" || shouldLoadGridCustomerPortal();
}

export type IAppConfig = unknown & {
  identityBaseApiUrl: "identity" | "om";
  identityBaseApiMethod: "PUT" | "POST";
  portalLinks?: IMenuLink[];
  dashboard?: DashboardComponent;
  isOMApp?: boolean;
};

export const DefaultAppConfig: IAppConfig = {
  identityBaseApiUrl: "identity",
  identityBaseApiMethod: "PUT",
  isOMApp: false,
};

export function useAppConfig(): IAppConfig {
  const config = useContext(ConfigContext);
  const isOMApp = useOMApp();

  const portalLinks = isOMApp ? [portalLinkOM] : getPortalLinks(config);
  const identityBaseApiUrl = isOMApp ? "om" : "identity";
  const identityBaseApiMethod = isOMApp ? "POST" : "PUT";
  const dashboard = isOMApp ? OMDashboard : Dashboard;

  return {
    ...config,
    identityBaseApiUrl,
    identityBaseApiMethod,
    portalLinks,
    dashboard,
    isOMApp,
  };
}
