import Grid from "@material-ui/core/Grid";
import { useDialog } from "muibox";
import { DataProvider } from "ra-core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { ConfigContext } from "..";
import { TS_AND_CS_KEY, TS_AND_CS_VERSION } from "../constants";
import Carousel from "../reusable-components/carousel/Carousel";
import CustomTitle from "../reusable-components/layout/CustomTitle";
import useUserContext from "../shared/useUserContext";
import { localStorageService } from "../shared/util/LocalStorageService";
import FeaturedApp, { FeaturedAppProps } from "./FeaturedApp";
import Footer from "./Footer";
import acsJpg from "./images/ACS_Tile.jpg";
import { default as auflsJpg } from "./images/AUFLS_Tile.jpg";
import backgroundJpg from "./images/Lines_107_large.jpg";
import SORegisterJpg from "./images/lines_dande.jpg";
import pocpJpg from "./images/manapouri-cardsize.jpg";
import nzgbJpg from "./images/NZGB_Tile.jpg";
import overlayJpg from "./images/TP_Pattern_Circular_Simplified_white.png";
import {
  portalLinkACS,
  portalLinkAUFLS,
  portalLinkDANDE,
  portalLinkNZGB,
  portalLinkPOCP,
} from "./links";
import MainFeaturedPost from "./MainFeaturedPost";
import { confirmTsAndCs } from "./TermsAndConditions";

const mainFeaturedPost = {
  title: "Operations Customer Portal",
  imageUrl: backgroundJpg,
  imageText: "Operations Customer Portal - Manapouri Background Photo",
  overlay: overlayJpg,
  content: `"## AUFLS`,
};

const featuredApps: FeaturedAppProps[] = [
  {
    shortName: "AUFLS",
    title: "Automatic Under-Frequency Load Shedding (AUFLS)",
    content: `An automatic system to electrically disconnect demand to help restore system frequency following a severe under-frequency event. This application allows North Island connected asset owners to submit their feeder configuration and annual load profile data. `,
    imageUrl: auflsJpg,
    imageText: "AUFLS application",
    appLink: portalLinkAUFLS,
  },
  {
    shortName: "ACS",
    title: "Asset Capability Statement (ACS)",
    content: `Contains information required for the System Operator to determine limitations in the operation of the asset for the safe and efficient operation of the grid. This application allows asset owners to submit and update information on their commissioned, modified, or decommissioned assets.`,
    imageUrl: acsJpg,
    imageText: "ACS application",
    appLink: portalLinkACS,
  },
  {
    shortName: "POCP",
    title: "Planned Outage Coordination Process (POCP)",
    content: `This application provides a mechanism that enables Asset Owners to provide information on their outages to the System Operator. Industry participants can upload and view outages in order to manage and co-ordinate outage requirements of plant and equipment connected to New Zealand’s National Grid.`,
    imageUrl: pocpJpg,
    imageText: "POCP application",
    appLink: portalLinkPOCP,
    hasGuestAccess: true,
  },
  {
    shortName: "NZGB",
    title: "New Zealand Generation Balance (NZGB)",
    content: `This application forecasts whether there will be enough generation capacity to securely meet projected load on the power system. It provides industry participants with a means of assessing the potential impact of planned outages on the generation balance and communicates any potential generation balance shortfalls over the next 200 days.`,
    imageUrl: nzgbJpg,
    imageText: "NZGB application",
    appLink: portalLinkNZGB,
    hasGuestAccess: true,
  },
  {
    shortName: "DANDE",
    title: "System Operator Register (SO Register)",
    content: `This application is the register kept by the System Operator to record dispensations and equivalence arrangements in accordance with the Electricity Industry Participation Code. It provides workflows for Asset Owners and the System Operator to manage applications for dispensations and equivalence arrangements, as well as managing consultations on decisions with industry.`,
    imageUrl: SORegisterJpg,
    imageText: "SO Register application",
    appLink: portalLinkDANDE,
    hasGuestAccess: true,
  },
];

export default function Dashboard() {
  const config = React.useContext(ConfigContext);
  const dataProvider: DataProvider = useDataProvider();
  const dialog = useDialog();
  const userContext = useUserContext();

  const [hasAgreed, setHasAgreed] = useState<boolean>(true);

  useEffect(() => {
    if (
      userContext.initialised &&
      /* guests don't need to accept T&C */
      !userContext.isGuest &&
      (!userContext.acceptedTsAndCs ||
        userContext.acceptedTsAndCs !== TS_AND_CS_VERSION)
    ) {
      setHasAgreed(false);
    }
  }, [userContext]);

  const recordTsAndCsAccepted = useCallback(() => {
    // we store this with the browser against the user or guest depending on the context
    if (userContext.isGuest) {
      localStorageService.setItem<string>(
        `${TS_AND_CS_KEY}.guest`,
        TS_AND_CS_VERSION
      );
    } else {
      localStorageService.setItem<string>(
        `${TS_AND_CS_KEY}.${userContext.email}`,
        TS_AND_CS_VERSION
      );
    }

    // and against the user on the server
    dataProvider
      .acceptTsAndCs(TS_AND_CS_VERSION)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dataProvider, userContext]);

  const acceptTsAndCs = useCallback(async () => {
    (await confirmTsAndCs(dialog)) && recordTsAndCsAccepted();
  }, [dialog, recordTsAndCsAccepted]);

  useEffect(() => {
    async function requestTsAndCsAcceptance() {
      await acceptTsAndCs()
        .then(() => {
          setHasAgreed(true);
        })
        .catch(() => undefined);
    }
    if (!hasAgreed) {
      requestTsAndCsAcceptance();
    }
  }, [acceptTsAndCs, hasAgreed]);

  const filteredFeaturedApps = featuredApps.filter((a) => {
    if (a.shortName === "DANDE") {
      return config.REACT_APP_DANDE_ENABLED === "TRUE";
    }
    return true;
  });

  return (
    <Grid>
      <CustomTitle title="portal.title.customerPortal"></CustomTitle>
      <MainFeaturedPost post={mainFeaturedPost} />
      <Grid
        container
        style={{
          display: "flex",
          width: "100%",
          marginLeft: "1px",
          marginRight: "1px",
          marginBottom: "1px",
        }}
        spacing={4}
      >
        <Carousel maxWidth={1800}>
          {filteredFeaturedApps.map((props) => {
            return <FeaturedApp key={props.title} {...props} />;
          })}
        </Carousel>
      </Grid>

      <Footer />
    </Grid>
  );
}
