import { createContext } from "react";
import { Organisation } from "../types";

export type IOrganisationContext = {
  organisation?: Organisation;
};

export const OrganisationContext = createContext({
  organisation: undefined,
} as IOrganisationContext);
