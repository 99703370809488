import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import deepEqual from "lodash/isEqual";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "react-admin";
import styled from "styled-components";

interface CarouselParams {
  children: any;
  maxWidth?: number;
}

const ContainerRelative = styled.div<{ max?: number }>`
  position: relative;
  max-width: ${({ max }) => `${max}px`};
  width: 100%;
`;

const Container = styled.div`
  max-width: 100%;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  .buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    button {
      background: #0000004e;
      border: none;
      padding: 8px 12px;
      cursor: pointer;
    }
    .prev {
      transform: translateX(10px);
    }
    .next {
      transform: translateX(-10px);
    }
  }
`;

interface StateCarouselParams {
  widthCarousel?: number;
  widthChildren?: number;
  qntChildren?: number;
  maxWidthCarousel?: number;
}

const Carousel: React.FC<CarouselParams> = ({
  children,
  maxWidth,
}: CarouselParams): JSX.Element => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const [stateCarousel, setStateCarousel] = useState<StateCarouselParams>();

  const handleCarousel = useCallback(() => {
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      const newStateCarousel = {
        ...stateCarousel,
        widthCarousel: carousel.clientWidth,
        qntChildren: carousel.children.length,
        widthChildren: carousel.children.item(0)?.clientWidth,
        maxWidthCarousel:
          (carousel.children.length - 1) *
          carousel.children.item(0)?.clientWidth!,
      };

      if (!deepEqual(stateCarousel, newStateCarousel)) {
        setStateCarousel(newStateCarousel);
      }
    }
  }, [stateCarousel, setStateCarousel]);

  const handleCarouselAction = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const carousel = carouselRef.current;
    switch (e.currentTarget.id) {
      case "next":
        return (carousel!.scrollLeft += stateCarousel?.widthChildren!);
      case "prev":
        return (carousel!.scrollLeft -= stateCarousel?.widthChildren!);
      default:
        return null;
    }
  };

  useEffect(() => handleCarousel(), [handleCarousel]);

  return (
    <ContainerRelative>
      <Container ref={carouselRef}>
        {children}
        {children.length > 5 ? (
          <div className="buttons">
            <Button
              label=""
              size="large"
              onClick={handleCarouselAction}
              id="prev"
              className="prev"
            >
              <ChevronLeftIcon />
            </Button>
            <Button
              label=""
              size="large"
              onClick={handleCarouselAction}
              id="next"
              className="next"
            >
              <ChevronRightIcon />
            </Button>
          </div>
        ) : null}
      </Container>
    </ContainerRelative>
  );
};

export default Carousel;
