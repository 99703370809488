import PropTypes from "prop-types";
import { Record } from "ra-core";
import { cloneElement, FC, ReactElement, useEffect } from "react";
import { useTranslate, warning } from "react-admin";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import { changePageTitle } from "../../shared/middlewares/actions";

export interface TitleProps {
  className?: string;
  defaultTitle?: string;
  record?: Record;
  title?: string | ReactElement;
}

/**
 * The purpose of CustomTitle is to set a page title by state rather than the
 * createPortal(..) method used by RA internally. It was found that when adding
 * additional components to the AppBar, this would result in some pages not having
 * a title show up, as the AppBar would re-render after the createPortal(..) call
 * had set the title element, causing the title to disappear.
 *
 * Using this component means you must embed <CustomTitle ...> in your page, rather
 * than rely on RA to automatically set the title based on your resource.
 */
export const CustomTitle: FC<TitleProps> = ({
  className,
  defaultTitle,
  record,
  title,
  ...rest
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  warning(!defaultTitle && !title, "Missing title prop in <Title> element");

  useEffect(() => {
    const titleElement = !title ? (
      <span className={className} {...rest}>
        {defaultTitle}
      </span>
    ) : typeof title === "string" ? (
      <span className={className} {...rest}>
        {translate(title, { _: title })}
      </span>
    ) : (
      cloneElement(title, { className, record, ...rest })
    );

    const updatedContent = ReactDOMServer.renderToStaticMarkup(titleElement);
    dispatch(changePageTitle({ title: updatedContent }));
  }, [className, defaultTitle, dispatch, record, rest, title, translate]);

  /*
   * On component unmount, reset the title
   */
  useEffect(() => () => {
    dispatch(changePageTitle({ title: "" }));
  });

  return <span />;
};

export const TitlePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
]);

CustomTitle.propTypes = {
  defaultTitle: PropTypes.string,
  className: PropTypes.string,
  record: PropTypes.any,
  title: TitlePropType,
};

export default CustomTitle;
