import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "markdown-to-jsx";
import React from "react";

const styles = (theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
});

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h4",
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: "h5" } },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: "subtitle1" },
    },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: "caption", paragraph: true },
    },
    p: { component: Typography, props: { variant: "body2", paragraph: true } },
    a: {
      component: ({ ...props }) => (
        <Link
          color="inherit"
          style={{
            color: "#00879E",
            fontSize: "18px",
          }}
          {...props}
        />
      ),
    },
    li: {
      component: withStyles(styles)<any>(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" variant="body2" {...props} />
        </li>
      )),
    },
  },
};

export default function Markdown(props) {
  return <ReactMarkdown options={options} {...props} />;
}
