import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { DialogProvider } from "muibox";
import React, { useContext, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useMount } from "react-use";
import { TS_AND_CS_KEY } from "./constants";
import { ConfigContext } from "./index";
import PortalRoutes from "./PortalRoutes";
import Loading from "./reusable-components/Loading";
import { TPMaterialTheme } from "./reusable-components/tp-materialui";
import { IUserPermissions } from "./shared/auth/authProvider";
import { LoginResultContext } from "./shared/context/LoginResultContext";
import { OCPDataProvider } from "./shared/dataProvider/OCPDataProvider";
import useOCPAuthProvider from "./shared/useOCPAuthProvider";
import { localStorageService } from "./shared/util/LocalStorageService";
import { useAppConfig } from "./shared/util/useAppConfig";
import { IAppState } from "./types";

const GUEST_ACCESSIBLE_PATHS = ["/pocp", "/nzgb", "/soregister"];
const GUEST_INACCESSIBLE_PATHS = [
  "/soregister/applications",
  "/soregister/tasks",
  "/soregister/users",
];

const App = () => {
  const config = useContext(ConfigContext);
  const appConfig = useAppConfig();
  const authProvider = useOCPAuthProvider();

  const [state, setState] = useState<IAppState>({
    dataProvider: null,
    isAuthReady: false,
    initFailed: false,
  });

  const portalTheme = createTheme(TPMaterialTheme);
  const dataProvider = OCPDataProvider(
    config.REACT_APP_PORTAL_SERVER_URL || "",
    (resource) => resource,
    appConfig
  );

  useMount(async () => {
    let authRedirectErrorMessage;
    try {
      /* This is a one-time step to check if we are returning from a redirect login */
      await authProvider.catchRedirectAuth();

      /* If a user doesn't have a current id token then attempt a login */
      if (!authProvider.hasValidIdToken()) {
        if (
          (window.location.pathname === "/" ||
            GUEST_ACCESSIBLE_PATHS.find((appName) =>
              window.location.pathname.includes(appName)
            )) &&
          !GUEST_INACCESSIBLE_PATHS.find((appName) =>
            window.location.pathname.includes(appName)
          )
        ) {
          /*
           * If the user is accessing the portal home page, or any guest accessible app
           * attempt to log them in silently.
           * This will not prompt for interaction.  If the silent login fails, and a user
           * will allow access as a guest.
           */
          await authProvider.silentLogin();
        } else {
          /*
           * If the user is accessing a secured page give the user the opurtunty to
           * login to gain access.  If the login fails, the user will be redirected to
           * an unauthorised page.
           */
          await authProvider.login(window.location.href);
        }
      }
    } catch (err) {
      authRedirectErrorMessage = err;
    }

    let validToken = authProvider.getIdTokenClaims();

    if (validToken) {
      dataProvider
        .get(`${appConfig.identityBaseApiUrl}/me`)
        .then((res) => {
          const permissions: IUserPermissions = res.data;
          if (!permissions.acceptedTsAndCs) {
            permissions.acceptedTsAndCs = localStorageService.getItem<string>(
              `${TS_AND_CS_KEY}.${permissions.email}`
            );
          }
          authProvider.setPermissions(permissions);
          setState(
            (current) =>
              ({
                ...current,
                dataProvider: dataProvider,
                isAuthReady: true,
                initFailed: false,
                authRedirectErrorMessage,
              } as IAppState)
          );
        })
        .catch(() => {
          setState(
            () =>
              ({
                initFailed: true,
                loadingMessage: "Failed to connect to data source",
              } as IAppState)
          );
        });
    } else {
      const permissions: IUserPermissions = {
        appPermissions: [],
        email: "guest",
        fullname: "Guest",
        orgPermissions: [],
        acceptedTsAndCs: localStorageService.getItem<string>(
          `${TS_AND_CS_KEY}.guest`
        ),
      };
      authProvider.setPermissions(permissions);
      setState(
        (current) =>
          ({
            ...current,
            dataProvider: dataProvider,
            isAuthReady: true,
            initFailed: false,
            authRedirectErrorMessage,
          } as IAppState)
      );
    }
  });

  if (!state.dataProvider || !state.isAuthReady) {
    return (
      <ThemeProvider theme={portalTheme}>
        <Loading
          isLoading={!state.initFailed}
          message={state.loadingMessage}
          contents={state.loadingContents}
        />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={portalTheme}>
      <DialogProvider>
        <Router>
          <LoginResultContext.Provider
            value={{
              authRedirectErrorMessage: state.authRedirectErrorMessage,
            }}
          >
            <PortalRoutes />
          </LoginResultContext.Provider>
        </Router>
      </DialogProvider>
    </ThemeProvider>
  );
};

export default App;
