import { DataProvider, GetListResult, GetOneResult } from "react-admin";

export const OMDataProvider = (dataProvider: DataProvider): DataProvider => ({
  ...dataProvider,
  getList: (resource, params) => {
    if (resource.endsWith("substations"))
      return (dataProvider.getList(resource, params).then((results) => {
        return new Promise<GetListResult>((resolve, reject) => {
          resolve({
            ...results,
            data: results.data.map((u) => ({ ...u, id: u.code })),
          });
        });
      }) as unknown) as any;
    return dataProvider.getList(resource, params);
  },

  getOne: (resource, params) => {
    if (resource.endsWith("distribution-lists")) {
      return dataProvider.getOne(resource, params).then((results) => {
        return (new Promise<GetOneResult>((resolve, reject) => {
          // we transform a list of substations into a list of strings wit substation ids to allow the edit screen to pre-populate.
          resolve({
            ...results,
            data: {
              ...results.data,
              substations: results.data.substations.map(({ code }) => code),
            },
          });
        }) as unknown) as any;
      });
    } else return dataProvider.getOne(resource, params);
  },
  create: (resource, params) => {
    if (resource.includes("detailed-outage-requirements")) {
      const { id } = (params as unknown) as { id: number };
      return dataProvider.create(`${resource}/${id}/response`, params);
    } else {
      return dataProvider.create(resource, params);
    }
  },
});
