import { makeStyles, Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useState } from "react";
import { ReactFloatingBalloons } from "react-floating-balloons";
import FeaturedApp, { FeaturedAppProps } from "./FeaturedApp";

interface MainFeaturedPostProps {
  post: {
    content: string;
    imageUrl: string;
    imageText: string;
    title: string;
    featuredApp: FeaturedAppProps;
    overlayHeight?: string;
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: "5%",
    marginLeft: "40%",
    marginRight: "40%",
    width: "20%",
  },
  featuredPost: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.only("xs")]: {
      height: "40vh",
    },
    [theme.breakpoints.only("lg")]: {
      height: "75vh",
    },
    [theme.breakpoints.only("xl")]: {
      height: "50vh",
    },
    [theme.breakpoints.only("md")]: {
      height: "43vh",
    },
    minHeight: "719px",
  },
  titleOverlay: {
    wordWrap: "break-word",
    position: "absolute",
    width: "50%",
    top: "33%",
    left: "16%",
    fontFamily: "'Calibri', 'sans-serif'",
    [theme.breakpoints.only("md")]: {
      top: "40%",
    },
    [theme.breakpoints.only("lg")]: {
      top: "40%",
    },
  },
}));

export default function OMMainFeaturedPost(props: MainFeaturedPostProps) {
  const { post } = props;
  const [counter, setCounter] = useState(0);

  const classes = useStyles(props.post.overlayHeight);

  const onClick = () => {
    if (counter < 10) {
      setCounter(counter + 1);
    } else if (counter >= 10) {
      setCounter(counter + 1);
    }
    if (counter > 10) {
      setCounter(0);
    }
  };

  return (
    <Paper
      className={classes.featuredPost}
      style={{
        backgroundPosition: "center bottom",
        backgroundImage: `url(${post.imageUrl})`,
      }}
    >
      <Grid container alignItems="stretch">
        <Grid item>
          <Typography
            variant="h2"
            onClick={onClick}
            className={classes.titleOverlay}
          >
            {post.title}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.paper}>
        <FeaturedApp
          key={props.post.featuredApp.title}
          {...props.post.featuredApp}
        />
      </div>

      {counter >= 10 ? (
        <ReactFloatingBalloons
          count={3}
          msgText="Free Colours!"
          colors={["blue", "green", "purple"]}
          popVolumeLevel={0.1}
        />
      ) : null}
    </Paper>
  );
}
