import { IUserContext } from "../../types";

export const CHANGE_USER_CONTEXT = "CHANGE_USER_CONTEXT";

export const changeUserContext = (userContext: IUserContext) => ({
  type: CHANGE_USER_CONTEXT,
  payload: userContext,
});

export const CHANGE_PAGE_TITLE = "CHANGE_PAGE_TITLE";

export interface IPageTitle {
  title: string;
}

export const changePageTitle = (pageTitle: IPageTitle) => ({
  type: CHANGE_PAGE_TITLE,
  payload: pageTitle,
});
