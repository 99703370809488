import { stringify } from "query-string";
import { DataProvider } from "ra-core";
import { DefaultAppConfig, IAppConfig } from "../util/useAppConfig";
import { fetchJson, IFetchResponse } from "./fetch";

const listToChunks = (inputArray: Array<any>, chunkSize: number = 25) =>
  inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

const sumTotal = (prev: number, resp: IFetchResponse) =>
  prev + (resp.json.page?.total || 0);

const concatItems = (prev: Array<any>, resp: IFetchResponse) =>
  prev.concat(resp.json.items);

/**
 * Maps react-admin queries to OCP backend
 *
 * getList     => GET http://my.api.url/posts?sort='title','ASC'&page=0&pageSize=25&q=SearchTerm
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?ids=123,456,789
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 */
export const OCPDataProvider = (
  apiUrl: string,
  resourceToPath = (resource) => resource,
  appConfig: IAppConfig = DefaultAppConfig,
  httpClient = fetchJson
): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const query = {
      page: page - 1,
      size: perPage,
    };

    // Add all filter params to query.
    Object.keys(params.filter || {}).forEach((key) => {
      query[`${key}`] = params.filter[key];
    });

    // Add sort parameter
    if (params.sort && params.sort.field) {
      const { field, order } = params.sort;
      query["sort"] = `${field},${order.toLowerCase()}`;
    }

    const url = `${apiUrl}/${resourceToPath(resource)}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      /* Handle either paginated or array response */
      const items = Array.isArray(json) ? json : json.items;
      const result = {
        data: items,
        total: json.page ? json.page.total : items.length,
      };
      return result;
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resourceToPath(resource)}/${params.id}`).then(
      ({ json }) => ({
        data: json,
      })
    ),

  getMany: (resource, params) => {
    const ids = listToChunks(params.ids);

    /* getMany can generate long urls, where the referer header size can cause 431,
       so we crudely circumvent this by chomping into individual requests */
    return Promise.all<IFetchResponse>(
      ids.map((idRange) =>
        httpClient(
          `${apiUrl}/${resourceToPath(resource)}?${stringify({
            ids: idRange,
          })}`
        )
      )
    ).then((responses: Array<IFetchResponse>) => ({
      data: responses.reduce(concatItems, []),
      total: responses.reduce(sumTotal, 0),
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const query = {
      page: page - 1,
      size: perPage,
      [params.target]: params.id,
    };

    // Add all filter params to query.
    Object.keys(params.filter || {}).forEach((key) => {
      query[`${key}`] = params.filter[key];
    });

    // Add sort parameter
    if (params.sort && params.sort.field) {
      const { field, order } = params.sort;
      query["sort"] = `${field},${order.toLowerCase()}`;
    }

    const url = `${apiUrl}/${resourceToPath(resource)}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      const result = {
        data: json.items,
        total: json.page.total,
      };
      return result;
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resourceToPath(resource)}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  // API has no support for updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resourceToPath(resource)}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resourceToPath(resource)}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resourceToPath(resource)}/${params.id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "text/plain",
      }),
    }).then(({ json }) => ({ data: json })),

  deleteWithOrgId: (resource, params) =>
    httpClient(
      `${apiUrl}/${resourceToPath(resource)}/${params.id}?orgId=${
        params.orgId
      }`,
      {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "text/plain",
        }),
      }
    ).then(({ json }) => ({ data: json })),

  // API has no support for multiple deletes, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resourceToPath(resource)}/${id}`, {
          method: "DELETE",
          headers: new Headers({
            "Content-Type": "text/plain",
          }),
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    })),

  get: (resource) =>
    httpClient(`${apiUrl}/${resourceToPath(resource)}`).then(({ json }) => ({
      data: json,
    })),

  upload: (resource, params) => {
    return httpClient(`${apiUrl}/${resourceToPath(resource)}`, {
      method: "POST",
      body: params.data,
    }).then(({ json }) => ({ data: {} }));
  },

  uploadWithResult: (resource, params) => {
    return httpClient(`${apiUrl}/${resourceToPath(resource)}`, {
      method: "POST",
      body: params.data,
    }).then(({ json }) => ({ data: json }));
  },

  post: (resource, params) => {
    return httpClient(`${apiUrl}/${resourceToPath(resource)}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: {} }));
  },

  acceptTsAndCs: (version: string) => {
    return httpClient(
      `${apiUrl}/${appConfig.identityBaseApiUrl}/me/accept_ts_and_cs?version=${version}`,
      {
        method: appConfig.identityBaseApiMethod,
      }
    ).then(({ json }) => ({ data: json }));
  },

  put: (resource, params) => {
    return httpClient(`${apiUrl}/${resourceToPath(resource)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: {} }));
  },
});
