import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import footerJpg from "./images/TP_Footer.png";
import { TermsAndConditions } from "./TermsAndConditions";

function Copyright() {
  const classes = useStyles();
  return (
    <>
      {"Copyright ©  "}
      <Link
        className={classes.copyrightSpacing}
        variant="body2"
        color="inherit"
        href="https://www.transpower.co.nz"
      >
        Transpower {new Date().getFullYear()}
      </Link>{" "}
    </>
  );
}

const Contact = forwardRef<any, any>((props, _) => {
  const classes = useStyles();
  return (
    <>
      <Link
        component={RouterLink}
        className={classes.buttonSpacing}
        variant="body2"
        color="inherit"
        to="/contact"
        {...props}
      >
        Contact
      </Link>{" "}
    </>
  );
});

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <>
      {" | "}
      <Link
        className={classes.buttonSpacing}
        variant="body2"
        color="inherit"
        href="https://www.transpower.co.nz/privacy-policy"
      >
        Privacy Policy
      </Link>
    </>
  );
};

const heightOfFooterRelative = 3;

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingTop: 6,
    paddingBottom: 6,
    zIndex: 99999,
    width: "100%",
    flexDirection: "column",
    marginTop: "auto",
    textAlign: "center",
    backgroundImage: `url(${footerJpg})`,
    backgroundRepeat: "repeat-x",
  },
  footerGraphic: {
    marginTop: 1,
    top: 1,
    verticalAlign: "top",
  },
  footerText: {
    bottom: 0,
    marginTop: `${heightOfFooterRelative}%`,
  },

  buttonSpacing: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: "1px",
  },
  copyrightSpacing: {
    marginBottom: "1px",
    marginRight: theme.spacing(2),
  },
  linkText: {
    fontSize: "1rem",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: "1px",
  },
}));

export default function Footer({ isOMApp = false }: { isOMApp?: boolean }) {
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Box className={classes.footerGraphic} />
      <Box className={classes.footerText}>
        <Typography variant="body2" color="inherit">
          <Copyright />
          {" | "}
          <TermsAndConditions isOMApp={isOMApp} />
          {" | "}
          {isOMApp ? (
            <>
              <Link
                className={classes.linkText}
                href={`mailto:grid_customer_portal@transpower.co.nz?subject=Grid Operation Customer Portal Problem - External`}
              >
                Contact
              </Link>
            </>
          ) : (
            <Contact />
          )}
          {isOMApp ? <PrivacyPolicy /> : <></>}
        </Typography>
      </Box>
    </Box>
  );
}
