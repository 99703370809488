import { makeStyles, Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useState } from "react";
import { ReactFloatingBalloons } from "react-floating-balloons";
import overlayJpg from "./images/TP_Pattern_Circular_Simplified_white.png";

interface MainFeaturedPostProps {
  post: {
    content: string;
    imageUrl: string;
    imageText: string;
    title: string;
  };
}

const useStyles = makeStyles((theme) => ({
  featuredPost: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.only("xs")]: {
      height: "40vh",
    },
    [theme.breakpoints.only("lg")]: {
      height: "43vh",
    },
    [theme.breakpoints.only("xl")]: {
      height: "50vh",
    },
    [theme.breakpoints.only("md")]: {
      height: "43vh",
    },
  },
  overlayImage: {
    position: "absolute",
    alignContent: "stretch",
    width: "50%",
    height: "100%",
    right: "55%",
    objectFit: "contain",
    backgroundPosition: "center top",
  },
  titleOverlay: {
    wordWrap: "break-word",
    position: "absolute",
    width: "50%",
    top: "33%",
    left: "16%",
    fontFamily: "'Calibri', 'sans-serif'",
    [theme.breakpoints.only("md")]: {
      top: "40%",
    },
    [theme.breakpoints.only("lg")]: {
      top: "40%",
    },
  },
  underlayOverlay: {
    wordWrap: "break-word",
    position: "absolute",
    width: "50%",
    top: "51%",
    left: "calc(16% + 3px)",
    fontFamily: "'Calibri', 'sans-serif'",
    [theme.breakpoints.only("md")]: {
      display: "none",
    },
    [theme.breakpoints.only("lg")]: {
      display: "none",
    },
  },
  loginButton: {
    margin: 6,
  },
}));

export default function MainFeaturedPost(props: MainFeaturedPostProps) {
  const { post } = props;
  const [counter, setCounter] = useState(0);

  const classes = useStyles();

  const onClick = () => {
    if (counter < 10) {
      setCounter(counter + 1);
    } else if (counter >= 10) {
      setCounter(counter + 1);
    }
    if (counter > 10) {
      setCounter(0);
    }
  };

  return (
    <Paper
      className={classes.featuredPost}
      style={{
        backgroundPosition: "center bottom",
        backgroundImage: `url(${post.imageUrl})`,
      }}
    >
      <Grid container alignItems="stretch">
        <Grid item>
          <Typography
            variant="h2"
            onClick={onClick}
            className={classes.titleOverlay}
          >
            {post.title}
          </Typography>
          <Typography variant="h4" className={classes.underlayOverlay}>
            {"Applications for power system participants."}
          </Typography>
          <img
            src={overlayJpg}
            className={classes.overlayImage}
            alt={post.title}
          />
        </Grid>
      </Grid>

      {counter >= 10 ? (
        <ReactFloatingBalloons
          count={3}
          msgText="Free Colours!"
          colors={["blue", "green", "purple"]}
          popVolumeLevel={0.1}
        />
      ) : null}
    </Paper>
  );
}
