import Grid from "@material-ui/core/Grid";
import { useDialog } from "muibox";
import { DataProvider } from "ra-core";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { ConfigContext } from "..";
import { TS_AND_CS_KEY, TS_AND_CS_VERSION } from "../constants";
import {
  IOrganisationContext,
  OrganisationContext,
} from "../om/context/OrganisationContext";
import { OMDataProvider } from "../om/OMDataProvider";
import CustomTitle from "../reusable-components/layout/CustomTitle";
import { OM_EXTERNAL_USER } from "../shared/constants";
import { OCPDataProvider } from "../shared/dataProvider/OCPDataProvider";
import useUserContext from "../shared/useUserContext";
import { localStorageService } from "../shared/util/LocalStorageService";
import { useAppConfig } from "../shared/util/useAppConfig";
import { IMenuLink } from "../types";
import Footer from "./Footer";
import windBackgroundJpg from "./images/wind-background.jpg";
import { portalLinkOM } from "./links";
import OMMainFeaturedPost from "./OMMainFeaturedPost";
import { confirmTsAndCs } from "./TermsAndConditions";

export default function OMDashboard() {
  const config = useContext(ConfigContext);

  const dataProvider: DataProvider = useDataProvider();
  const omDataProvider = OMDataProvider(
    OCPDataProvider(
      config.REACT_APP_PORTAL_SERVER_URL || "",
      (resource) => `om/${resource}`,
      useAppConfig()
    )
  );
  const dialog = useDialog();
  const userContext = useUserContext();
  const [appLink, setAppLink] = useState<IMenuLink>(portalLinkOM);
  const [
    organisationContext,
    setOrganisationContext,
  ] = useState<IOrganisationContext>({ organisation: undefined });
  const [organisationLoaded, setOrganisationLoaded] = useState(false);

  useEffect(() => {
    if (
      userContext.orgPermission &&
      userContext.orgPermission.orgId &&
      userContext.appPermissions?.includes(OM_EXTERNAL_USER) &&
      !organisationLoaded
    ) {
      const omAppOrgPermission = userContext.allOrgPermissions.find((orgPerm) =>
        orgPerm.permission?.startsWith("OM.")
      );
      omDataProvider
        .get(`organisations/identity/${omAppOrgPermission?.orgId}`)
        .then((result) => {
          const { data } = result;
          setAppLink({
            ...portalLinkOM,
            href: `${portalLinkOM.href}/organisations/${data.code}/detailed-outage-requirements`,
          });
          setOrganisationContext({ organisation: data });
          setOrganisationLoaded(true);
        });
    }
  }, [omDataProvider, userContext, organisationLoaded]);

  const recordTsAndCsAccepted = useCallback(() => {
    // we store this with the browser against the user or guest depending on the context
    if (userContext.isGuest) {
      localStorageService.setItem<string>(
        `${TS_AND_CS_KEY}.guest`,
        TS_AND_CS_VERSION
      );
    } else {
      localStorageService.setItem<string>(
        `${TS_AND_CS_KEY}.${userContext.email}`,
        TS_AND_CS_VERSION
      );
    }

    // and against the user on the server
    dataProvider
      .acceptTsAndCs(TS_AND_CS_VERSION)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dataProvider, userContext]);

  const acceptTsAndCs = useCallback(async () => {
    (await confirmTsAndCs(dialog, true)) && recordTsAndCsAccepted();
  }, [dialog, recordTsAndCsAccepted]);

  useEffect(() => {
    async function requestTsAndCsAcceptance() {
      await acceptTsAndCs()
        .then(() => {})
        .catch(() => undefined);
    }
    const { acceptedTsAndCs, isGuest } = userContext;
    if (!isGuest && !acceptedTsAndCs) {
      requestTsAndCsAcceptance();
    }
  }, [acceptTsAndCs, userContext]);

  return (
    <OrganisationContext.Provider value={organisationContext}>
      <Grid>
        <CustomTitle title="omApp.title.customerPortal"></CustomTitle>
        <OMMainFeaturedPost
          post={{
            title: "",
            imageUrl: windBackgroundJpg,
            imageText:
              "Operations Customer Portal - Manapouri Background Photo",
            overlayHeight: "75vh",
            content: `"## AUFLS`,
            featuredApp: {
              shortName: "OM",
              title: "Outage Management (OM)",
              content: `The Outage Management app provides information related to the
                  Detailed Outage Requirements affecting your organisation.<br />Use this application to review and respond to the Detailed
                  Outage Requirements.`,
              imageText: "OM application",
              appLink,
            },
          }}
        />
        <Grid
          container
          style={{
            display: "flex",
            width: "20%",
            marginLeft: "40%",
            marginRight: "40%",
          }}
          spacing={4}
        ></Grid>

        <Footer isOMApp={true} />
      </Grid>
    </OrganisationContext.Provider>
  );
}
