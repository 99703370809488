export const ER_ORG_ADMIN: string = "ER.ORG_EXTERNAL_ADMIN";
export const ER_ORG_EDITOR: string = "ER.ORG_EXTERNAL_EDITOR";
export const ER_ORG_VIEWER: string = "ER.ORG_EXTERNAL_VIEWER";
export const ER_EXTERNAL_USER: string = "ER.EXTERNAL_USER";
export const ER_INTERNAL_VIEWER: string = "ER.INTERNAL_VIEWER";
export const ER_INTERNAL_EDITOR: string = "ACS.INTERNAL_EDITOR";
export const ER_INTERNAL_ADMIN: string = "ER.INTERNAL_ADMIN";

export const ACS_ORG_ADMIN: string = "ACS.ORG_EXTERNAL_ADMIN";
export const ACS_ORG_EDITOR: string = "ACS.ORG_EXTERNAL_EDITOR";
export const ACS_ORG_VIEWER: string = "ACS.ORG_EXTERNAL_VIEWER";
export const ACS_EXTERNAL_USER: string = "ACS.EXTERNAL_USER";
export const ACS_INTERNAL_VIEWER: string = "ACS.INTERNAL_VIEWER";
export const ACS_INTERNAL_EDITOR: string = "ACS.INTERNAL_EDITOR";
export const ACS_INTERNAL_ADMIN: string = "ACS.INTERNAL_ADMIN";
export const ACS_INTERNAL_DOCUMENT_VIEWER: string =
  "ACS.INTERNAL_DOCUMENT_VIEWER";

export const POCP_ORG_ADMIN: string = "POCP.ORG_EXTERNAL_ADMIN";
export const POCP_ORG_EDITOR: string = "POCP.ORG_EXTERNAL_EDITOR";
export const POCP_ORG_VIEWER: string = "POCP.ORG_EXTERNAL_VIEWER";
export const POCP_EXTERNAL_USER: string = "POCP.EXTERNAL_USER";
export const POCP_INTERNAL_VIEWER: string = "POCP.INTERNAL_VIEWER";
export const POCP_INTERNAL_EDITOR: string = "POCP.INTERNAL_EDITOR";
export const POCP_INTERNAL_ADMIN: string = "POCP.INTERNAL_ADMIN";

export const NZGB_EXTERNAL_USER: string = "NZGB.EXTERNAL_USER";
export const NZGB_INTERNAL_VIEWER: string = "NZGB.INTERNAL_VIEWER";
export const NZGB_INTERNAL_ADMIN: string = "NZGB.INTERNAL_ADMIN";

export const DANDE_ORG_ADMIN: string = "DANDE.ORG_EXTERNAL_ADMIN";
export const DANDE_ORG_EDITOR: string = "DANDE.ORG_EXTERNAL_EDITOR";
export const DANDE_ORG_VIEWER: string = "DANDE.ORG_EXTERNAL_VIEWER";
export const DANDE_EXTERNAL_USER: string = "DANDE.EXTERNAL_USER";
export const DANDE_INTERNAL_USER: string = "DANDE.INTERNAL";
export const DANDE_INTERNAL_VIEWER: string = "DANDE.INTERNAL_VIEWER";
export const DANDE_INTERNAL_EDITOR: string = "DANDE.INTERNAL_EDITOR";
export const DANDE_INTERNAL_ADMIN: string = "DANDE.INTERNAL_ADMIN";

export const NO_APP_PERMISSION: string = "Guest";
export const REGISTERED_USER: string = "Registered User";

// Grid OCP OM: App Permissions
export const OM_EXTERNAL_USER = "OM.EXTERNAL_USER";
export const OM_INTERNAL_ADMIN = "OM.INTERNAL_ADMIN";
export const OM_INTERNAL_VIEWER = "OM.INTERNAL_VIEWER";
export const OM_INTERNAL_EDITOR = "OM.INTERNAL_EDITOR";

// Grid OCP OM: Org Permissions
export const OM_ORG_EXTERNAL_ADMIN = "OM.ORG_EXTERNAL_ADMIN";
export const OM_ORG_EXTERNAL_DOR_RESPONDER = "OM.ORG_EXTERNAL_DOR_RESPONDER";
export const OM_ORG_EXTERNAL_VIEWER = "OM.ORG_EXTERNAL_VIEWER";
